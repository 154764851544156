<template>
    <div class="container">
        <label>
            <input type="radio" :id="id" :name="name" :value="value" @change="handleCheck" :checked="checked"/>
            <div class="button"></div>
            <label :for="id">{{label}}</label>
        </label>
    </div>
</template>

<script>
export default {
    name: 'B2bRadio',
    props: {
        id: {
            type: String,
            require: true
        },
        label: String,
        value: {
            type: [String , Boolean]
        },
        checked: {
            type: Boolean,
            default: false
        },
        name: String
    },
    mounted(){
        if(this.checked) this.$emit('update:val', this.value)
    },
    methods: {
        handleCheck(e){
            if(e.target.checked) {
                this.$emit('update:val', this.value)
            }
        }
    }
}
</script>

<style scoped>
.container {
    height: 30px;
    position: relative;
    margin: 15px 15px 15px 0px;
}

label label {
    position: absolute;
    font-size: 12px;
    margin-left: 35px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: left;
    color: #363660;
}

input {
    display: none;
}

.button::before {
    content: "";
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    left: 0;
    border: solid 1px #4cbfde;
    border-radius: 20px;
    background-color: #ffffff;
}

.button::after {
    content: "";
    position: absolute;
    display: block;
    width: 12px;
    height: 12px;
    margin: 9px;
    left: 0px;
    border-radius: 7px;
}

label input:checked + .button::after {
    background-color: #4cbfde;
}
</style>