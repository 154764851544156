<template>
    <div style="width: 100%; height: 50px; z-index: 13; position: relative;" >
        <div class="container" >
            <div class="select">
                <div class="select-bar">
                    <div v-if="selected" class="selected">
                        <div class="selected_text">{{selected.label}}</div>
                        <div class="flag "><img :src="require('../../assets/icons/' + selected.flag)"/></div>
                        <div class="delete" @click="handleDelete"> <img :src="require('../../assets/icons/close.svg')"/> </div>
                    </div>
                    <input v-else v-model="model" class="" @input="handleSearch"/>
                    <div class="placeholder">
                        <p :class="{ 'p2': model == '' && !selected, 'p1': model != '' || (model == '' && selected)}">{{placeholder}}</p>
                    </div>
                </div>
                <div v-if="!selected" class="dropdown-icon"> <img :src="require('../../assets/icons/arrow-ios-down.svg')"/> </div>
            </div>
            <div class="dropdown">
                <div v-for="item in dataShow" :key="item.label" class="item" @click="() => handleSelect(item)">
                    <div class="selected_text">{{item.label}}</div>
                    <div class="flag ">
                        <img :src="item.flag ? require('../../assets/icons/' + item.flag) : '' "/>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'B2bSelect',
    props: {
        data: {
            type: Array,
            require: true
        },
        title: String,
        placeholder: String,
    },
    data(){
        return {
            model: '',
            dataShow: [...this.data],
            selected: null
        }
    },
    methods: {
        handleSearch(e){
            const val = e.target.value;
            this.dataShow = this.data.filter(i => {
                let resp = false;
                if(i.label.search(val) !== -1) resp = true;
                return resp
            })

        },
        handleSelect(item){
            this.selected = item
            this.model = '';
            this.$emit('update:val', this.selected)
        },
        handleDelete(){
            this.selected = null;
            this.model = '';
             this.dataShow = this.data
            this.$emit('update:val', this.selected)
        }
    },
}
</script>

<style scoped>
.container {
    position: absolute;
    contain:content;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: solid 1px #c4c4c4;
    background-color: #ffffff;
    z-index: 20;
    height: auto;
    max-height: 50px;
    overflow: hidden;
    transition: all 0.5s;
    padding-right: 5px;
    cursor: text;
}
.select {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container:focus-within {
    height: auto;
    max-height: 250px;
}

.selected {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.selected_text{
    width: 100%;
    height: 100%;
    padding: 20px 16px 10px 16px;
    color: #363660;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
}

.select-bar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 10;
}

input {
    width: 100%;
    height: 50px;
    padding: 20px 16px 10px 16px;
    background: none;
    z-index: 1;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #363660;
}

input:focus {
    outline: none;
}

.dropdown-icon, .delete {
    width: 25px;
    height: 25px;
    border-radius: 5px;    
    cursor: text;
}

.delete {
    padding-top: 2px;
    cursor: pointer;
}

.flag {
    width: 75px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-content: center;
    opacity:0;
}

/* .dropdown-icon:hover {
    background: #f3f3f3;
} */

.delete:hover {
    background: #f3f3f3;
}

.dropdown {
    width: 100%;
    max-height: 200px;
    display: flex;
    background: white;
    flex-direction: column;
    background-color: #ffffff;
    animation: all 0.5s;
    overflow-y: auto;
    overflow-x: hidden;
}

.item {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.item:hover {
    background: #f6f7fb;
}

.placeholder {
    position: absolute;
    top: 0;
    margin: 5px 15px;
    z-index: 0;
    background: white;
}

.p1 {
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: normal;
    text-align: left;
    color: #e94a73;
    transform: translateY(0px);
    transition: all 0.3s;
}

.p2 {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #9696b4;
    transform: translateY(15px);
}

</style>