<template>
    <div class="my_input" >
        <div style="position: absolute; width: 100%; height: 100%; display: flex; flex-direction: row;">
            <input v-if="type == 'card'"  class="input-card"  type="password" v-model="model" @input="handleChange"  :maxlength="length"/>
            <input v-else :type="type" :id="id" min="1" v-model="model" @input="handleChange" @focus="focus" @change="change"  :maxlength="length"/>
            <div v-if="type == 'card'" style="width: 112px; height: 50px; padding: 16px"><img width="77" height="16" :src="require('../../assets/images/color/icn-card.svg')"></div>
        </div>
        <div class="placeholder_container">
            <p :class="{ 'p1': !model, 'p2': model}">{{placeholder}}</p>
            <p v-if="type == 'card'" class="dot-card-value ">{{modl_dots}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'B2bInput',
    props: {
        title: String,
        placeholder: String,
        type: String,
        length: String,
        value: String || Number,
        id: String
    },
    data(){
        return {
            model: '',
            modl_dots : ''
        }
    },
    methods: {
        handleChange(){
            this.$emit('update:val', this.model)
            this.toDotValue()       
            },
        toDotValue(){
            let dots = ''
            for(let i = 0; i < this.model.length; i++){
                dots += '.'
            }
            if(this.model.length > 0) this.modl_dots = dots.match(/.{1,4}/g).join(' ');
            else this.modl_dots = ''

        },
        focus() {
            this.$emit('focus')
        },
        change() {
            this.$emit('change')
        },
        setModel(model) {
            this.model = model
            this.handleChange()
        } 
    }
}
</script>

<style scoped>
.my_input {
    width: 100%;
    height: 50px;
    position: relative;
    margin: 2px 0px;
    position: relative;
    background: white;
    z-index: 5;
    border: solid 1px #c4c4c4;
  border-radius: 4px;
    
}

.my_input:focus-within {
    border: 2px solid black;
}

.input {
    position: absolute;
}

.card {
    visibility: hidden;
}

input {
  width: 100%;
  height: 50px;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
  padding: 12px 16px 12px 16px;
  background: none;
  z-index: 10;
  outline: none;
}

.input-card {
  color: transparent;
  caret-shape: bar ;
}

.dot-card-value {
    position: absolute;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
  transition: all 0.3s;
  transform: translateY(-8px);
  height: 20px;
  line-height: 20px;
  contain: content;
}

.my_input:focus-within + div .dot-card-value  {
   animation-duration: 1s;
   animation-name: clignoter;
   animation-iteration-count: infinite;
   transition: none;
}
@keyframes clignoter {
  0%   { border-right: 1px solid black; }
  40%   {border-right: 1px solid transparent; }
  100% { border-right: 1px solid black;  }
}


.placeholder_container {
    position: absolute;
    padding: 4px 16px 5px 16px;
    z-index: 0;
}

.p2 {
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: left;
  color: #e94a73;
  transform: translateY(0px);
  transition: all 0.2s;
}

.p1 {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  transform: translateY(13px);
  color: #9696b4;
  transition: all 0.3s;
}


</style>