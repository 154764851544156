<template>
  <div class="flex container ml-auto mr-auto" style="background-color: #f6f7fb">
    <div class="loader" v-if="loader == true">
      <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="p-2 lg:p-0 flex lg:flex justify-center items-center flex-grow">
      <div
        class="lg:pt-0 lg:pr-10 lg:pb-0 lg:pl-48"
        style="width: 100%"
        id="fixed"
      >
        <div>
          <p class="title1">Souscription à l’offre ExVal</p>
          <form
            class="grid grid-cols-6 gap-3"
            @submit.prevent="submit"
            id="payment-form"
          >
            <p class="title3 col-span-6">
              S’agit-il d’un achat professionnel ?
            </p>

            <div class="grid grid-cols-6 gap-3 col-span-6" style="padding: 0">
              <B2bRadio
                class="col-start-1"
                name="achatPro"
                id="achatPro-oui"
                label="Oui"
                :value="true"
                v-model:val="achatPro"
                checked
              />
              <B2bRadio
                class="col-start-2"
                name="achatPro"
                id="achatPro-non"
                label="Non"
                :value="false"
                v-model:val="achatPro"
              />
            </div>

            <p class="title2 col-span-6">1. Informations</p>

            <B2bInput
              v-if="achatPro == true"
              class="col-span-2 lg:col-span-3"
              placeholder="Nom de l'entreprise"
              length="20"
              v-model:val="society_name"
              v-bind:class="society_nameErr == true ? 'hasError' : ''"
            />
            <B2bInput
              v-if="achatPro == true"
              class="col-span-2 lg:col-span-3"
              type="email"
              placeholder="Email entreprise"
              @change="validateSocEmail()"
              length="20"
              v-model:val="society_email"
              v-bind:class="society_emailError == true ? 'hasError' : ''"
            />

            <div
              class="col-span-2 lg:col-span-3"
              style="position: relative; z-index: 20"
            >
              <B2bSelect
                class="col-span-4 lg:col-span-2 slct"
                placeholder="Genre"
                :data="genderData"
                v-model:val="selected"
                :value="selected"
                v-bind:class="selectedError == true ? 'hasError' : ''"
              />
            </div>
            <B2bInput
              placeholder="Nom"
              v-model:val="firstname"
              type="text"
              class="col-span-2 lg:col-span-3"
              v-bind:class="firstError == true ? 'hasError' : ''"
            />
            <B2bInput
              placeholder="Prénom"
              v-model:val="lastname"
              type="text"
              class="col-span-2 lg:col-span-3"
              v-bind:class="lastError == true ? 'hasError' : ''"
            />
            <B2bInput
              placeholder="Adresse email"
              v-model:val="email"
              type="email"
              class="col-span-2 lg:col-span-3"
              @change="validateEmail()"
              v-bind:class="emailError == true ? 'hasError' : ''"
            />

            <!--B2bInput placeholder="Entreprise" class="col-span-2 lg:col-span-3" type="text" v-model:val="society_name" v-bind:class="society_nameErr == true ? 'hasError' : ''"/-->

            <B2bSelect
              v-if="achatPro == true"
              class="col-span-2 pe-0 pl-0 lg:col-span-3 slct"
              placeholder="Secteur d'activite"
              :data="options"
              v-model:val="activity"
              :value="activity"
              v-bind:class="activityError == true ? 'hasError' : ''"
            />

            <div class="col-span-2 pe-0 pl-0 lg:col-span-3" style="">
              <!--input placeholder="France" class="col-span-2" />
              <input placeholder="+ 33" class="col-span-4" /-->
              <vue-tel-input
                v-model="phone"
                class="col-span-6 lg:col-span-3"
                v-bind:class="phoneError == true ? 'hasError' : ''"
                id="phone"
                placeholer="Numéro de téléphone"
              ></vue-tel-input>
            </div>

            <B2bInput
              class="col-span-2 lg:col-span-3"
              v-if="achatPro == true"
              placeholder="Siren"
              length="30"
              v-model:val="siren"
              v-bind:class="sirenError == true ? 'hasError' : ''"
            />
            <B2bInput
              class="col-span-2 lg:col-span-3"
              placeholder="Nombre de compte"
              length="30"
              ref="qte"
              v-model:val="quantity_user"
              :value="quantity_user"
              type="number"
              v-bind:class="quantity_userError == true ? 'hasError' : ''"
            />

            <B2bInput
              class="col-span-6"
              placeholder=""
              length="30"
              ref="add"
              name="address"
              id="adresse"
              v-model:val="address"
              :value="address"
            />

            <B2bInput
              class="col-span-6 lg:col-span-3"
              ref="zip"
              v-model:val="zipcode"
              :value="zipcode"
              id="zipcode"
              placeholder="Code postal"
              length="30"
            />
            <B2bInput
              class="col-span-6 lg:col-span-3"
              ref="ville"
              v-model:val="city"
              :value="city"
              id="city"
              placeholder="Ville"
              length="30"
            />
            <B2bInput
              placeholder="Mot de passe"
              v-model:val="password"
              type="password"
              class="col-span-2 lg:col-span-3"
              v-bind:class="passwordError == true ? 'hasError' : ''"
            />
            <B2bInput
              placeholder="Confirmation de mot de passe"
              v-model:val="password_conf"
              type="password"
              class="col-span-2 lg:col-span-3"
              v-bind:class="passwordConfError == true ? 'hasError' : ''"
            />

            <p class="title2 placeholder2 col-span-3">2. Mode de paiement</p>

            <B2bInput
              class="col-span-6"
              placeholder="Nom du titulaire"
              length="30"
              v-model:val="card_owner"
            />

            <!--B2bInput class="col-span-6" placeholder="Numéro de carte bancaire" type="card" length="16"/>

            <B2bInput class="col-span-2" placeholder="Expiration" length="5"/>
            <div class="col-span-2">
              <B2bInput placeholder="cvv" length="3"/>
              <span class="textInput">3 chiffres au verso de la carte</span>
            </div>
            <B2bInput class="col-span-2" placeholder="Code postal" length="5"/-->

            <div class="col-span-6">
              <div id="card-element"></div>
              <div id="card-errors" role="alert"></div>
            </div>

            <p v-if="achatPro == true" class="title3 col-span-6">
              Avez-vous un numéro de TVA ?
            </p>

            <div v-if="achatPro == true" class="col-span-6" style="padding: 0">
              <B2bRadio
                class="col-start-1"
                name="numTva"
                id="numTva-oui"
                label="Oui, je vais l’indiquer maintenant"
                :value="true"
                v-model:val="numTva"
                checked
              />
              <B2bRadio
                class="col-start-2"
                name="numTva"
                id="numTva-non"
                label="Non, j’en fournirai un plus tard"
                :value="false"
                v-model:val="numTva"
              />
            </div>

            <p
              v-if="achatPro == true && numTva == true"
              class="title3 col-span-6"
            >
              Numéro de TVA
            </p>

            <div
              v-if="achatPro == true && numTva == true"
              class="col-span-6 lg:col-span-4"
            >
              <B2bInput
                placeholder="FR030399393"
                length="11"
                v-model:val="tva"
                v-bind:class="tvaError == true ? 'hasError' : ''"
              />
              <span class="textInput"
                >Veuillez inclure le code de votre pays</span
              >
            </div>

            <p class="title3 col-span-6">Coupon</p>

            <div class="col-span-6 lg:col-span-4">
              <B2bInput
                placeholder="Votre coupon"
                v-model:val="coupon"
                @change="checkCoupon()"
                v-bind:class="couponError == true ? 'hasError' : ''"
              />
              <span
                class="textInput"
                style="color: red"
                v-if="couponError == true"
                >Code coupon invalide</span
              >
            </div>
            <!--  to='/tarifs/souscription/validation'  -->
            <div
              class="col-span-6 lg:col-span-4"
              style="margin-top: 80px; margin-bottom: 100px"
            >
              <button
                class="text-white font-bold p-4 rounded bg-secondary w-full"
                type="submit"
                v-if="canSubmit == true"
                @click="push"
              >
                Commencer votre version essai
              </button>
              <button
                v-if="canSubmit == false"
                id="disable"
                disabled
                class="text-white font-bold p-4 rounded bg-secondary w-full"
                type="submit"
              >
                Commencer votre version essai
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div id="right" class="flex-none hidden lg:block lg:block">
      <div
        class="flex flex-col justify-center items-center bg-quaternary"
        style="height: 100%"
      >
        <Substract
          v-if="plan != null"
          style="width: 274px; height: 325.6px"
          :quantity="quantity_user"
          :plan="plan"
          :tva="achatPro"
          :frequency="$route.query.frequency"
        />
        <div style="contain: none; height: 256.6px">
          <img :src="require('../assets/images/virtual-card.svg')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import B2bInput from "../components/public/B2bInput.vue";
import B2bSelect from "../components/public/B2bSelect.vue";
import Substract from "../components/b2b9/Substract.vue";
import B2bRadio from "../components/public/B2bRadio.vue";
//import Selecter from '../components/b2b3/select.vue'
import axios from "axios";
const isVisible = (elem) =>
  !!elem &&
  !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

export default defineComponent({
  name: "B2b9",
  components: {
    B2bInput,
    B2bSelect,
    Substract,
    B2bRadio,
    //Selecter
  },
  data() {
    return {
      selected: null,
      selectedError: false,
      entreprise: null,
      entrepriseError: false,
      achatPro: false,
      numTva: false,
      genderData: [
        {
          label: "Mr",
          value: "MR",
          flag: "arrow-ios-down.svg",
        },
        {
          label: "Mm",
          value: "Mm",
          flag: "arrow-ios-down.svg",
        },
      ],
      sectorData: [
        {
          label: "Mr",
          value: "MR",
          flag: "arrow-ios-down.svg",
        },
        {
          label: "Mm",
          value: "Mm",
          flag: "arrow-ios-down.svg",
        },
      ],
      society_email: "",
      society_emailError: false,
      phone: "",
      phoneError: false,
      firstname: "",
      firstError: false,
      lastname: "",
      lastError: false,
      email: "",
      emailError: false,
      society_name: "",
      society_nameErr: false,
      activity: "",
      activityError: false,
      password: "",
      passwordError: false,
      password_conf: "",
      passwordConfError: false,
      siren: "",
      sirenError: false,
      quantity_user: 0,
      society_number: "",
      contry: "",
      city: "",
      zipcode: "",
      tva: "",
      tvaError: false,
      coupon: "",
      couponError: false,
      address: "",
      addressError: false,
      card_owner: "",
      card_ownerError: false,
      quantity_userError: false,
      loader: false,

      modalType: false,
      options: [
        {
          id: 1,
          label: "Notaire",
          value: "Notaire",
          flag: "arrow-ios-down.svg",
          checked: false,
        },
        {
          id: 2,
          label: "Conseiller Financier",
          value: "Conseiller Financier",
          flag: "arrow-ios-down.svg",
          checked: false,
        },
        {
          id: 3,
          label: "Expert Immobilier",
          value: "Expert Immobilier",
          flag: "arrow-ios-down.svg",
          checked: false,
        },
        {
          id: 4,
          label: "Banque",
          value: "Banque",
          flag: "arrow-ios-down.svg",
          checked: false,
        },
        {
          id: 5,
          label: "Conseiller en Gestion de Patrimoine",
          value: "Conseiller en Gestion de Patrimoine",
          flag: "arrow-ios-down.svg",
          checked: false,
        },
        {
          id: 6,
          label: "Autres",
          value: "Autres",
          flag: "arrow-ios-down.svg",
          checked: false,
        },
      ],
      canSubmit: false,
      card: null,
      stripe: null,
      plan: null,
      mapCenter: { lat: 48.856613, lng: 2.352222 },
    };
  },
  mounted() {
    const self = this;
    setTimeout(() => {
      self.loadStripe();
    }, 300);
    ///api/v1/static-page/plan
    const q = this.$route.query.frequency;
    if (q != null) {
      axios({
        method: "GET",
        url: "https://api.exval.fr/api/v1/static-page/plan",
        params: {
          frequency: this.$route.query.frequency,
          plan_id: this.$route.query.plan_id,
        },
      })
        .then(function (response) {
          if (response.data.code == "200") {
            self.plan = response.data.datas;
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    } else {
      window.location.href = "/tarifs";
    }
    this.$refs.qte.setModel(1);
    setTimeout(function () {
      document
        .querySelector('input[name="telephone"]')
        .setAttribute("placeholder", "Votre numéro de téléphone");
    }, 1000);
    if (window.google != undefined && typeof window.google.maps === "object") {
      const adresse = document.getElementById("adresse");
      const options = {
        componentRestrictions: { country: "FR" },
        fields: [
          "formatted_address",
          "geometry",
          "name",
          "address_components",
          "place_id",
        ],
        origin: this.mapCenter,
        strictBounds: false,
        //types: ["addresses"],
      };
      const autocomplete = new window.google.maps.places.Autocomplete(
        adresse,
        options
      );
      //autocomplete.bindTo("bounds", map);

      window.google.maps.event.addListener(
        autocomplete,
        "place_changed",
        function () {
          const place = autocomplete.getPlace().address_components;
          let code = place.filter(function (item) {
            return item.types.includes("postal_code");
          });
          let ville = place.filter(function (item) {
            return item.types.includes("locality");
          });

          let adresse = autocomplete.getPlace().formatted_address;
          const pos = autocomplete.getPlace().geometry.location;
          if (code.length == 0) {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode(
              {
                location: {
                  lat: pos.lat(),
                  lng: pos.lng(),
                },
              },
              (results, status) => {
                if (status === "OK") {
                  if (results[0]) {
                    const stablePlace = results[0].address_components;
                    code = stablePlace.filter(function (item) {
                      return item.types.includes("postal_code");
                    });

                    const zipcode = code.length > 0 ? code[0].long_name : "";
                    const city = ville.length > 0 ? ville[0].long_name : "";
                    const address = adresse;
                    self.$refs.zip.setModel(zipcode);
                    self.$refs.ville.setModel(city);
                    self.$refs.add.setModel(address);
                  }
                }
              }
            );
          } else {
            const zipcode = code.length > 0 ? code[0].long_name : "";
            const city = ville.length > 0 ? ville[0].long_name : "";
            const address = adresse;
            self.$refs.zip.setModel(zipcode);
            self.$refs.ville.setModel(city);
            self.$refs.add.setModel(address);
          }
        }
      );
    }
  },
  methods: {
    toggleModalType() {
      this.modalType = !this.modalType;
      if (this.modalType == true) {
        const element = document.getElementById("modal-container");
        this.hideOnClickOutside(element);
      }
    },

    hideOnClickOutside(element) {
      const outsideClickListener = (event) => {
        //const bien = document.getElementById('modal-bien')
        if (element.contains(event.target) == false && isVisible(element)) {
          this.hideModal();
          removeClickListener();
        }
      };
      const removeClickListener = () => {
        document.removeEventListener("click", outsideClickListener);
      };
      document.addEventListener("click", outsideClickListener);
    },

    hideModal() {
      this.modalType = false;
    },
    onChange(value) {
      const options = this.options.map(function (item) {
        if (value.includes(item.value)) {
          if (item.checked == false) item.checked = true;
          else item.checked = false;
        } else {
          item.checked = false;
        }
        return item;
      });
      this.options = [...options];

      this.activity = value[0];
    },

    validateEmail() {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const v = re.test(String(this.email).toLowerCase());
      this.emailError = v == false ? true : false;
    },

    validateSocEmail() {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const v = re.test(String(this.society_email).toLowerCase());
      this.society_emailError = v == false ? true : false;
    },

    loadStripe() {
      /**
       * Stripe
       **/
      const self = this;

      var stripe = window.Stripe("pk_test_6PMnFnJLSGuCcG2mMYYK9iyv");
      this.stripe = stripe;
      // Create an instance of Elements.
      var elements = stripe.elements();
      var styleStripe = {
        base: {
          color: "#32325d",
          fontFamily: '"Prompt", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      };
      var card = elements.create("card", {
        hidePostalCode: true,
        style: styleStripe,
      });
      card.mount("#card-element");
      card.addEventListener("change", function (event) {
        var displayError = document.getElementById("card-errors");
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
          self.canSubmit = true;
        }
      });
      this.card = card;
      // Submit the form with the token ID.
    },
    updateCard(token, validate = true) {
      const self = this;
      if (validate == true) {
        self.loader = true;
        axios({
          method: "POST",
          url: "https://api.exval.fr/api/v1/static-page/check",
          params: {
            email: this.email,
            ip: "",
          },
        })
          .then(function (response) {
            self.loader = false;

            if (response.data == false) {
              self.sub(true, token);
            } else if (response.data == "dataFraudExist") {
              window.Swal.fire({
                title:
                  "<h1 style='color:#497f7f;'>Activation de l'abonnement</h1>",
                type: "",
                html:
                  "" +
                  "Il semble que vous avez déjà bénéficié d'une période d'essai gratuite. Votre inscription activera donc votre abonnement immédiatement." +
                  "",
                showCloseButton: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "S'abonner sans période d'essai",
                cancelButtonText: "Annuler",
                cancelButtonColor: "#DD402D",
                confirmButtonColor: "#497f7f",
              }).then(function (isConfirm) {
                if (isConfirm.value) {
                  self.loader = true;
                  self.sub(true, token);
                }
              });
            } else {
              //show error internal
              this.emailError = true;
              window.Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "L'email existe déjà !",
                footer: "",
              });
            }
          })
          .catch(function (err) {
            console.log(err);
            self.loader = false;
          });
      } else {
        window.Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Une erreur s'est produite!",
          footer: "",
        });
      }
    },
    sub(bool, token) {
      const self = this;
      self.loader = true;
      const tel = document.querySelector('input[name="telephone"]');
      axios({
        method: "POST",
        url: "https://api.exval.fr/api/v1/static-page/create-subscription",
        params: {
          quantity_user: this.quantity_user,
          plan_id: this.$route.query.plan_id,
          frequency: this.$route.query.frequency,
          isSubscribeActive: bool,
          stripeToken: token,
          num_tva: this.tva,
          card_owner: this.card_owner,
          form: {
            gender: this.selected.value,
            firstname: this.firstname,
            lastname: this.lastname,
            society_name: this.society_name,
            society_email: this.society_email,
            activity: this.activity.value,
            autre_detail: "",
            siren: this.siren,
            address: this.address,
            zipcode: this.zipcode,
            city: this.city,
            phone: tel,
            email: this.email,
            codeCoupon: this.coupon,
            plainPassword1: this.password,
          },
        },
      })
        .then(function (response) {
          self.loader = false;
          if (response.data.substr(0, 2) == "pi") {
            // This can be found on invoice.payment_intent.client_secret
            var paymentIntentSecret = response.data;

            self.stripe
              .handleCardPayment(paymentIntentSecret)
              .then(function (result) {
                self.loader = false;

                if (result.error) {
                  document.location.href = "/";
                } else {
                  var intervalCheck = setInterval(function () {
                    axios({
                      method: "POST",
                      url: "https://api.exval.fr/api/v1/static-page/check-subscription-status",
                      params: {
                        email: self.email,
                      },
                    })
                      .then(function (response) {
                        if (response.data == "") {
                          //avec message failed
                          document.location.href = "/";
                        } else if (response.data.length > 1) {
                          clearInterval(intervalCheck);
                          var idSubscription = response.data;
                          var interval = setInterval(function () {
                            axios({
                              method: "POST",
                              url: "https://api.exval.fr/api/v1/static-page/get-subscription-status",
                              params: {
                                id: idSubscription,
                              },
                            })
                              .then(function (response) {
                                if (response.data != null) {
                                  clearInterval(interval);
                                  document.location.href = "/";
                                }
                              })
                              .catch(function (err) {
                                console.log(err);
                              });
                          }, 1000);
                        }
                      })
                      .catch(function (err) {
                        console.log(err);
                      });
                  }, 1000);
                }
              });
          } else {
            window.Swal.fire({
              icon: "success",
              title: "Félicitations",
              text: "Votre compte a bien été créer !",
              footer: "",
            });
            setTimeout(function () {
              document.location.href = "/";
            }, 500);
          }
        })
        .catch(function (err) {
          console.log(err);
          self.loader = false;
        });
    },
    stripeTokenHandler(token, validate = true) {
      var form = document.getElementById("payment-form");
      var hiddenInput = document.createElement("input");
      hiddenInput.setAttribute("type", "hidden");
      hiddenInput.setAttribute("name", "stripeToken");
      hiddenInput.setAttribute("value", token.id);
      form.appendChild(hiddenInput);
      this.updateCard(token.id, validate);
    },
    push() {
      const t = this.validateForm();
      const self = this;
      this.stripe.createToken(this.card).then(function (result) {
        if (result.error) {
          var errorElement = document.getElementById("card-errors");
          errorElement.textContent = result.error.message;
        } else {
          self.stripeTokenHandler(result.token, t);
        }
      });
    },
    checkCoupon() {
      const self = this;
      self.canSubmit = false;
      if (this.coupon != "") {
        axios({
          method: "POST",
          url: "https://api.exval.fr/api/v1/static-page/check-coupon",
          params: {
            codeCoupon: this.coupon,
          },
        })
          .then(function (response) {
            if (response.data == false) {
              self.couponError = true;
              self.canSubmit = false;
            } else {
              self.couponError = false;
              self.canSubmit = true;
            }
          })
          .catch(function (err) {
            console.log(err);
            self.canSubmit = false;
            self.couponError = false;
          });
      } else {
        self.canSubmit = true;
        self.couponError = false;
      }
    },
    validateForm() {
      this.selectedError = false;
      this.entrepriseError = false;
      this.phoneError = false;
      this.firstError = false;
      this.lastError = false;
      this.emailError = false;
      this.society_nameErr = false;
      this.activityError = false;
      this.passwordError = false;
      this.passwordError = false;
      this.passwordConfError = false;
      this.sirenError = false;
      this.tvaError = false;
      this.couponError = false;
      this.card_ownerError = false;
      this.quantity_userError = false;
      this.society_emailError = false;

      if (this.selected == null || this.selected == "") {
        this.selectedError = true;
      }
      if (this.achatPro == true) {
        if (this.society_email == null || this.society_email == "") {
          this.entrepriseError = true;
        }

        if (this.society_name == "") {
          this.society_nameErr = true;
        }

        if (this.siren == "") {
          this.sirenError = true;
        }

        if (this.activity == "") {
          this.activityError = true;
        }
      }

      if (this.firstname == "") {
        this.firstError = true;
      }
      if (this.lastname == "") {
        this.lastError = true;
      }
      if (this.email == "") {
        this.emailError = true;
      }
      if (this.password == "") {
        this.passwordError = true;
      }
      if (this.card_owner == "") {
        this.card_ownerError = true;
      }
      if (this.password != "") {
        if (this.password_conf == "") {
          this.passwordConfError = true;
        }
      }
      if (this.quantity_user < 1) {
        this.quantity_userError = true;
      }
      if (this.achatPro == true && this.numTva == true) {
        if (this.tva == "") {
          this.tvaError = true;
        }
      }

      if (
        this.selectedError == false &&
        this.phoneError == false &&
        this.firstError == false &&
        this.lastError == false &&
        this.emailError == false &&
        this.society_nameErr == false &&
        this.activityError == false &&
        this.passwordError == false &&
        this.passwordConfError == false &&
        this.sirenError == false &&
        this.tvaError == false &&
        this.couponError == false
      ) {
        return true;
      } else {
        document.getElementById("fixed").scrollIntoView({
          behavior: "smooth",
        });
        return false;
      }
    },
  },
});
</script>

<style lang="css" scoped>
.textInput {
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
}

.title1 {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: left;
  color: #363660;
  margin-top: 40px;
}
.title2 {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
  margin-top: 40px;
  margin-bottom: 15px;
}
.title3 {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
  margin: 25px 0px 0px 0px;
}
input::placeholder {
  opacity: 1; /* Firefox */
  font-weight: bold;
}
input:nth-child(5)::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #363660;
  font-weight: bold;
}
.modal-type-bien {
  z-index: 90;
}
#right .bg-quaternary {
  position: absolute;
  left: 0;
  height: 100vh !important;
}
.hasError {
  border: 1px solid red;
}
#fixed {
  max-height: 100vh;
  overflow-y: scroll;
}

#fixed::-webkit-scrollbar {
  width: 2px;
  display: none;
}
#right {
  position: relative;
}
#card-element {
  height: 50px;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
  padding: 17px 16px 13px 16px;
  background: none;
  z-index: 10;
  outline: none;
  background: #fff;
  border: solid 1px #c4c4c4;
  border-radius: 4px;
}
#card-errors {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: red;
}
#disable {
  opacity: 0.4;
  cursor: not-allowed;
}
</style>